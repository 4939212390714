<template>
  <div class="page-container">
    <!-- 导航栏 -->
    <el-header class="zg--header--main">
      <img class="zg--header--logo" src="https://image.qll-times.com/2020/05/d3c842b2adb3438ea13ff55c43689201.jpg">
      <label class="zg--header--title">商机圈子平台</label>
      <div class="zg--header--button">
        <el-button type="primary" @click="updatePoster">保存</el-button>
      </div>        
    </el-header>

    <!-- 主区域 -->
    <div class="left">
      <div class="brochure-container">
        <p v-if="!configs.logo" class="tips-none">请上传海报图片</p>
        <img v-else class="poster-img" :src="configs.logo">
        <img v-if="configs.delivery" class="poster-qrcode" :class="qrcodePosition"
          src="https://img0.qll-times.com/cdn/card/common/miniprogram-qrcode.jpeg">
      </div>
    </div>

    <div class="right">
      <div class="brochure-form">
        <!-- 是否开启二维码 -->
        <div class="form-item">
          <div class="item-label">显示二维码</div>
          <div class="item-content">
            <div style="height: 36px">
              <el-switch v-model="configs.delivery" active-color="#0091FF" inactive-color="#999"></el-switch>
            </div>
          </div>
        </div>

        <!-- 二维码位置 -->
        <div class="form-item" v-if="configs.delivery">
          <div class="item-label">二维码位置</div>
          <div class="item-content">
            <el-radio-group v-model="configs.position">
              <el-radio-button label="plt">左上</el-radio-button>
              <el-radio-button label="ptc">上中</el-radio-button>
              <el-radio-button label="prt">右上</el-radio-button>
              <el-radio-button label="prb">右下</el-radio-button>
              <el-radio-button label="pbc">下中</el-radio-button>
              <el-radio-button label="plb">左下</el-radio-button>
            </el-radio-group>
          </div>
        </div>

        <!-- 海报分类 -->
        <div class="form-item">
          <div class="item-label">海报分类</div>
          <div class="item-content">
            <el-select v-model="selectValue" placeholder="请选择海报分类">
              <el-option v-for="item in optionList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </div>
        </div>

        <!-- 海报名称 -->
        <div class="form-item">
          <div class="item-label">海报名称</div>
          <div class="item-content" style="width: 195px">
            <el-input v-model="configs.title" maxlength="10" show-word-limit placeholder="请输入海报名称"></el-input>
          </div>
        </div>

        <!-- 海报封面 -->
        <div class="form-item">
          <div class="item-label">海报图片</div>
          <div class="item-content">
            <file-upload accepts=".png,.jpeg,.jpg" @on-success="uploadLogo">
              <div class="brochure-logo">
                <img v-if="configs.logo" :src="configs.logo">
                <template  v-else>
                  <p class="add">+</p><p>立即上传</p>
                </template>
              </div>
            </file-upload>
            <p class="tips-text">建议尺寸：750x1334</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import uoloadImage from "@/websites/cms/js/dialog"
import upload from "@/websites/cms/components/FileUpload"
export default {
  data(){
    return {
      configs: { // 海报配置
        title: "", // 海报标题
        logo: "", // 海报logo
        delivery: true, // 显示二维码开关
        position: 'plt'
      },
      info: { //个人公司信息
        companyLogo:'',//企业LOGO
        profileImg: '', //头像
        profileName: '', //姓名
        profilePosition: '' //职位
      },
      selectValue:'',//分类select选中value 
      number:0,//海报样式index
      optionList: [],//select的数据
    }
  },
  created(){
    if(this.$route.query.sid) this.getPoster();
    this.posterCategoryList();//调用海报分类列表
    this.getMyCompany();//获取企业信息
    this.getMyInfo();//获取个人信息
  },
  computed: {
    qrcodePosition(){
      return `qrcode-position-${this.configs.position}`
    }
  },
  methods: {
    // 保存海报前的校验
    checkConfigs(){
      let temp = true;
      if(this.configs.title.length < 2){
        this.$message.error("海报名称至少2个字");
        return false;
      }
      if(!this.configs.logo){
        this.$message.error("请上传封面图片");
        return false; 
      }
      if(!this.selectValue){
        this.$message.error("请选择海报的分类");
        return false; 
      }
      return temp;
    },

    //获取海报分类列表Select数据对象
    async posterCategoryList() {
      let  res = await this.$ZGManager.materialCategoryList({type: 51});
        if(res.status != 200) return;
        this.optionList = res.data;
    },

    // 创建/更新海报
    async updatePoster(){
      if(!this.checkConfigs()) return;
      let res;
      if(this.$route.query.sid){
        let data = {
          title: this.configs.title,
          configs: JSON.stringify(this.configs),
          categoryId: this.selectValue
        }
        res = await this.$ZGManager.updateMaterial(this.$route.query.sid, data);
        if(res.status != 200) return;
        this.$message.success("修改成功");
      }else{
        let params = {
          type: 51,
          title: this.configs.title,
          configs: JSON.stringify(this.configs),
          categoryId: this.selectValue
        }
        res = await this.$ZGManager.addMaterial(params);
        if(res.status != 200) return;
        console.log('新增海报',res.data.id);
        this.$router.replace({ query: { sid: res.data.id } });
        this.$message.success("创建成功");
      }
    },

    // 查询海报配置
    async getPoster(){
      let res = await this.$ZGManager.getMaterial(this.$route.query.sid)
      if(res.status != 200) return
      Object.assign(this.configs, JSON.parse(res.data.configs))
      if(this.configs.position.length != 2 || !this.configs.position.startsWith('p')){
        this.configs.position = 'plt'
      }
      this.selectValue = res.data.categoryId 
    },

    // 裁剪海报背景
    crooperBrochureBg(){
      uoloadImage.show({
        type: 'crooperImage',
        cropWidth: 233,
        cropHeight: 350,
        fixedArray: [233, 350],
        isfixedBox: true,
        selected: (item) => {
          this.selectBrochureBg(item);
        }
      })
    },

    //获取企业信息
    async getMyCompany() {
      let res = await this.$ZGManager.getMyCompany();
      if (res.status !== 200) {
        return
      };
      this.info.companyLogo = res.data.logo;
    },

    //获取个人信息
    async getMyInfo() {
      let res = await this.$ZGManager.getMyInfo();
      if (res.status !== 200) {
        return
      };
      this.info.profileImg = res.data.headImg;
      this.info.profileName = res.data.name;
      this.info.profilePosition = res.data.position;
    },

    // 选择海报背景 1默认背景 2自定义背景
    selectBrochureBg(item){
      if(this.configs.bg.type == 1){
        this.configs.bg.selected = item.id;
        this.configs.bg.defaultUrl = item.url;
      }else if(this.configs.bg.type == 2){
        this.configs.bg.customUrl = item.url;
      }
    },
    
    // 上传封面图
    uploadLogo(data){
      this.configs.logo = data.url;
    },
  },
  components:{
    'file-upload': upload
  }
}
</script>
<style lang="scss" scoped>
.page-container{
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.left{
  width: calc(100% - 750px);
  height: calc(100% - 64px);
  float: left;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  .brochure-container{
    width: 375px;
    height: auto;
    background-color: #fff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    margin: 50px auto;
    position: relative;
    .tips-none{
      line-height: 603px;
      text-align: center;
      font-size: 16px;
      color: #666;
    }
    .poster-img{
      max-width: 100%;
    }
    .poster-qrcode{
      width: 56px;
      height: 56px;
      padding: 2px;
      background-color: #FFF;
      border-radius: 50%;
      position: absolute;
    }
    .qrcode-position-plt{
      left: 30px;
      top: 30px;
    }
    .qrcode-position-ptc{
      left: 157.5px;
      top: 30px;
    }
    .qrcode-position-prt{
      right: 30px;
      top: 30px;
    }
    .qrcode-position-prb{
      right: 30px;
      bottom: 30px;
    }
    .qrcode-position-pbc{
      left: 157.5px;
      bottom: 30px;
    }
    .qrcode-position-plb{
      left: 30px;
      bottom: 30px;
    }
  }
}
.right{
  width: calc(750px - 60px);
  height: calc(100% - 114px);
  padding: 30px;
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: auto;
  float: right;
  .brochure-form{
    width: 100%;
    .form-item{
      margin-bottom: 20px;
      .item-label{
        text-align: right;
        width: 110px;
        height: 40px;
        line-height: 40px;
        color: #666666;
      }
      .item-content{
        position: relative;
        width: calc(100% - 140px);
        margin-top: -40px;
        margin-left: 140px;
        ::v-deep .el-switch {
          position: absolute;
          top: 8px;
          left: 0;
        }
        .brochure-logo{
          width: 130px;
          height: 130px;
          border: 1px dashed #bbb;
          border-radius: 5px;
          box-sizing: border-box;
          background-color: #f5f5f5;
          text-align: center;
          position: relative;
          overflow: hidden;
          .add{
            font-size: 50px;
            font-weight: 100;
            margin-top: 10px;
          }
          img{
            max-width: 100%;
            max-height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
}
</style>